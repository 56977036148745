<template>
    <div>
        <Nuevo ref="Nuevo" :dialog="dialog" @dialog="dialog = $event" @cerrarModal="cerrarModal" :Item="itemSelected"
            v-if="verNuevo" />
        <MensajeTransicion :color="color" :text="mensaje" v-if="verMensaje" :top="true" :bottom="false" />

        <v-card>
            <v-toolbar color="secondary" dark>
                <span class="text-h6"> {{ Titulo }} : {{ ItemTotal }}</span>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                            <a target="_blank" href="https://helpdesk.tiresoft.pe/#mant_inf_vehiculos"
                                style="text-decoration:none !important; color: white;">
                                <v-icon dark>mdi-help-circle</v-icon>
                            </a>
                        </v-btn>
                    </template>
                    <span>¿Necesitas ayuda?</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>

            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                :loading="Loading" class="elevation-1" loading-text="Cargando... Por favor, espere"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                @click="editarItem(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="margin-left: 2px" height="22" width="22" color="#FF5733" fab x-small dark
                                @click="instalar(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-wrench</v-icon>
                            </v-btn>
                        </template>
                        <span>Instalar/Desinstalar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import router from "@/router";
import Nuevo from "./nuevo.vue";
import exportFromJSON from "export-from-json";
import MensajeTransicion from "../Alerta.vue";
import global from "@/global";

export default {
    props: ["Titulo", "Cabecera", "Items", "ItemTotal", "Excel", "Crear", "Loading"],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected: {
                id_planta: null,
            },
            color: "success",
            mensaje: "",
            verMensaje: false,
            verNuevo: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        mensajeUsuarioNoAutorizado: function () {
            this.color = "#FA694A";
            this.verMensaje = true;
            this.mensaje = "No esta autorizado para realizar la operación";
            setTimeout(() => [(this.verMensaje = false)], 4000);
        },

        nuevoItem() {
            let autorizacion = global._autorizacion_usuario(this.Permisos, 79);
            if (autorizacion) {
                const fecha = new Date();
                this.itemSelected = {
                    id: "",
                    id_configuracion: "",
                    placa: "",
                    prox_reencauche: "",
                    id_remanente: "",
                };
                this.dialog = true;
                this.verNuevo = false
                this.$nextTick().then(() => {
                    this.verNuevo = true;
                });
            } else {
                this.mensajeUsuarioNoAutorizado();
            }
        },
        editarItem(item) {
            //this.cargarConfiguracion(item.id_tipo);
            //this.cargarPosicionNeumatico(item.id_configuracion);
            item.ruta = parseInt(item.ruta);
            item.codigo = item.codigo == "-" ? null : item.codigo;
            item.id_planta = item.id_planta == "-" ? null : item.id_planta;
            item.edit_kilometraje = item.edit_kilometraje==undefined ? 0 : item.edit_kilometraje;
            item.editkm = item.editkm==undefined ? 0 : item.editkm; 
            this.itemSelected = { ...item };
            this.dialog = true;
            this.verNuevo = false;
            this.$nextTick().then(() => {
                this.verNuevo = true;
            });
        },
        instalar(item) {
            router.push("/vehiculo/instalacion/" + item.id);
        },
        DescargarExcel() {
            const data = this.Items;
            const nombreArchivo = "download";
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt
            exportFromJSON({
                data,
                nombreArchivo,
                exportType
            });
        },
        cerrarModal(valor) {
            this.verNuevo = false;
        }
    },
};
</script>
