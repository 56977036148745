<template>
    <div class="pa-5">
        <v-col lg="12">
            <v-row justify="center">
                <v-col lg="12">
                    <v-card>

                        <v-toolbar color="secondary" dark>
                            <span class="text-h6"> {{ Cliente.razon_social }} </span>
                            <v-spacer></v-spacer>
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                        <a target="_blank" href="https://helpdesk.tiresoft.pe/#reporte-generar-consolidado"
                                            style="text-decoration:none !important; color: white;">
                                            <v-icon dark>mdi-help-circle</v-icon>
                                        </a>
                                    </v-btn>
                                </template>
                                <span>¿Necesitas ayuda?</span>
                            </v-tooltip>
                        </v-toolbar>

                        <v-card-subtitle>Reporte consolidado de neumáticos</v-card-subtitle>
                        <!--
                    <v-toolbar height="60" color="#545554" dark>
                        Reporte consolidado de {{ Cliente.razon_social }}
                    </v-toolbar>
            -->
                        <v-card-text>
                            <v-form ref="form" v-model="valid">
                                <v-row>
                                    <v-col cols="12" lg="12" md="12" sm="12" xs="12">
                                        <img :src="rutaImagenFirma" alt="firma" width="150px" height="70px"
                                            style="margin-left: 20px" />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                                        <v-text-field type="text" v-model="nombreusuario" hide-details="auto" readonly
                                            required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="12" xs="12">
                                        <v-autocomplete :items="Years" v-model="Item.year" label="Año *"
                                            :rules="[(v) => !!v || 'El año es requerido']" @change="onMeses(Item.year)"
                                            class="mt-5" dense></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="12" xs="12">
                                        <v-autocomplete :items="Months" v-model="Item.month1" label="Mes inicial *"
                                            class="mt-5" :rules="[(v) => !!v || 'El mes es requerido']"
                                            dense></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" lg="2" md="2" sm="12" xs="12">
                                        <v-autocomplete :items="Months" v-model="Item.month2" label="Mes final *"
                                            class="mt-5" :rules="[(v) => !!v || 'El mes es requerido']"
                                            dense></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success" small :loading="loader" @click="consultarDatos" :disabled="!valid">
                                Consultar
                            </v-btn>

                            <v-btn color="error" small :disabled="buttonExportar" @click="generarPDF(Item)">
                                Exportar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <div v-if="visualizarReporte == true">
                <!--Seguimiento del reporte consolidado-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card class="pa-5">
                            <table class="letra-10 table-responsive" style="width: 100%">
                                <tr style="background-color: #6d6b6b">
                                    <td class="border texto-cen" colspan="5">
                                        <strong>
                                            <font color="white">Seguimiento del Reporte Consolidado</font>
                                        </strong>
                                    </td>
                                </tr>
                                <tr style="background-color: #6d6b6b">
                                    <td class="border texto-cen">
                                        <strong>
                                            <font color="white">No enviado</font>
                                        </strong>
                                    </td>
                                    <td class="border texto-cen">
                                        <strong>
                                            <font color="white">En proceso</font>
                                        </strong>
                                    </td>
                                    <td class="border texto-cen">
                                        <strong>
                                            <font color="white">Aceptado</font>
                                        </strong>
                                    </td>
                                    <td class="border texto-cen">
                                        <strong>
                                            <font color="white">Rechazado</font>
                                        </strong>
                                    </td>
                                    <td class="border texto-cen">
                                        <strong>
                                            <font color="white">Fecha actualización</font>
                                        </strong>
                                    </td>
                                </tr>
                                <tr v-if="EstadoReporte">
                                    <td class="border texto-cen"></td>
                                    <td class="border texto-cen"></td>
                                    <td class="border texto-cen">
                                        <v-icon color="blue lighten-2"
                                            v-if="EstadoReporte.estado == 'APROBADO'">mdi-checkbox-marked-circle</v-icon>
                                    </td>
                                    <td class="border texto-cen"></td>
                                    <td class="border texto-cen">
                                        {{ EstadoReporte.created_at }}
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td class="border texto-cen">&nbsp;</td>
                                    <td class="border texto-cen"></td>
                                    <td class="border texto-cen"></td>
                                    <td class="border texto-cen"></td>
                                    <td class="border texto-cen"></td>
                                </tr>
                            </table>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Recomendaciones-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Recomendaciones
                            </v-toolbar>
                            <v-container class="pa-4">
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea autocomplete="recomendacion" label="Recomendación"
                                        v-model="recomendacion.descripcion" rows="3"></v-textarea>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-card-actions>
                                    <v-btn color="success" @click="guardarRecomendacion()">
                                        Guardar recomendación
                                    </v-btn>
                                </v-card-actions>

                                <br />
                                <table class="letra-10" style="width: 100%">
                                    <tr>
                                        <td class="border texto-cen" style="width: 90%">
                                            <strong>
                                                <font color="black">Recomendaciones</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="black">Acciones</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in Recomendaciones" :key="index">
                                        <td class="border texto-just">
                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea class="letra-11" clear-icon="mdi-close-circle"
                                                    v-model="item.recomendaciones" rows="2"></v-textarea>
                                            </v-col>
                                        </td>
                                        <td class="border texto-just" style="padding: 10px">
                                            <v-btn style="margin-left: 2px" height="22" width="22" color="blue lighten-2"
                                                fab x-small dark @click="guardarRecomendacion(item)">
                                                <v-icon x-small>mdi-content-save</v-icon>
                                            </v-btn>
                                            <v-btn style="margin-left: 2px" height="22" width="22" color="orange" fab
                                                x-small dark @click="eliminarRecomendacion(index, item)">
                                                <v-icon x-small>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </table>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Equipos inspeccionados-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Equipos Inspeccionados
                            </v-toolbar>
                            <v-container class="pa-4 text-center">
                                <div class="text-center">
                                    <table class="letra-10 table-responsive" style="width: 100%">
                                        <tr>
                                            <td class="no-border" style="width: 15%"></td>
                                            <td class="no-border" style="width: 15%"></td>
                                            <td class="no-border" style="width: 15%"></td>
                                            <td class="no-border" style="width: 15%"></td>
                                            <td class="no-border" style="width: 15%"></td>
                                            <td class="no-border" style="width: 15%"></td>
                                            <td class="no-border" style="width: 15%"></td>
                                        </tr>
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen" style="width: 60%" colspan="4">
                                                <strong>
                                                    <font color="white">Equipo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 39%" colspan="3">
                                                <strong>
                                                    <font color="white">Neumático</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen">
                                                <strong>
                                                    <font color="white">Tipo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen">
                                                <strong>
                                                    <font color="white">Marca</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen">
                                                <strong>
                                                    <font color="white">Modelo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen">
                                                <strong>
                                                    <font color="white">Total</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen">
                                                <strong>
                                                    <font color="white">Delantero</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen">
                                                <strong>
                                                    <font color="white">Posterior</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen">
                                                <strong>
                                                    <font color="white">Total</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr v-for="(item, index) in EquiposInspeccionados" :key="index">
                                            <td class="border texto-cen">{{ item.nomtipo }}</td>
                                            <td class="border texto-cen">{{ item.marca }}</td>
                                            <td class="border texto-cen">{{ item.modelo }}</td>
                                            <td class="border texto-cen">
                                                {{ item.total_vehiculos }}
                                            </td>
                                            <td class="border texto-cen">
                                                {{ item.medidaDelanteros }}
                                            </td>
                                            <td class="border texto-cen">
                                                {{ item.medidaPosteriores }}
                                            </td>
                                            <td class="border texto-cen">
                                                {{ item.total_neumaticos }}
                                            </td>
                                        </tr>
                                        <tr v-if="EquiposInspeccionados.length == 0">
                                            <td class="border texto-cen" colspan="7">
                                                No hay equipos inspeccionados
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Distribución de medida de neumáticos y posición de rueda segun marca-->
                <v-row justify="center">
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Distribución de Medidas de Neumáticos
                            </v-toolbar>
                            <v-container class="pa-4" v-if="DistribucionMedidasNeumaticos.length > 0">
                                <div class="text-center">
                                    <div>
                                        <canvas ref="elemento" id="pieMedidas" style="max-height: 400px"></canvas>
                                    </div>
                                </div>
                            </v-container>
                            <v-container v-else> 
                                <div class="text-center"> 
                                    No hay Distribución de Medidas de Neumáticos
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Posición de rueda según marca
                            </v-toolbar>
                            <v-container class="pa-4" v-if="PosicionRuedaSegunMarca.length > 0">
                                <canvas ref="elemento" id="pieMarcas" style="max-height: 400px"></canvas>
                            </v-container>
                            <v-container v-else> 
                                <div class="text-center"> 
                                    No hay Posición de rueda según marca
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Distribución de marcas por eje-->
                <v-row justify="center">
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Distribución de Marcas por eje direccional
                            </v-toolbar>
                            <v-container class="pa-4"  v-if="DistribucionEjeDireccional.length > 0">
                                <div class="text-center">
                                    <div>
                                        <canvas ref="elemento" id="barDireccional" style="max-height: 400px"></canvas>
                                    </div>
                                </div>
                            </v-container>
                            <v-container v-else> 
                                <div class="text-center"> 
                                    No hay Distribución de Marcas por eje direccional
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Distribución de Marcas por eje tracción
                            </v-toolbar>
                            <v-container class="pa-4" v-if="DistribucionEjeTraccion.length > 0">
                                <canvas ref="elemento" id="barTraccion" style="max-height: 400px"></canvas>
                            </v-container>
                            <v-container v-else> 
                                <div class="text-center"> 
                                    No hay Distribución de Marcas por eje tracción
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Distribución de Marcas por eje de apoyo
                            </v-toolbar>
                            <v-container class="pa-4" v-if="DistribucionEjeLibre.length > 0">
                                <div class="text-center">
                                    <div>
                                        <canvas ref="elemento" id="barLibre" style="max-height: 400px"></canvas>
                                    </div>
                                </div>
                            </v-container>
                            <v-container v-else> 
                                <div class="text-center"> 
                                    No hay Distribución de Marcas por eje de apoyo
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col lg="5"> </v-col>
                </v-row>
                <!--Resumen de neumáticos en mal estado-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Resumen de Neumáticos En Mal Estado
                            </v-toolbar>
                            <v-container class="pa-4">
                                <table class="letra-10" style="width: 100%" id="table">
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen" style="width: 8%">
                                            <strong>
                                                <font color="white">Placa</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 5%">
                                            <strong>
                                                <font color="white">Pos</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Eje</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Marca</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Medida</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Modelo</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Estado</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Diseño</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Serie</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">NSD</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Observaciones</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">imagen</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in NeumaticosMalEstado" :key="index">
                                        <td class="border texto-cen">{{ item.placa }}</td>
                                        <td class="border texto-cen">
                                            {{ item.neumatico_posicion }}
                                        </td>
                                        <td class="border texto-cen">{{ item.eje }}</td>
                                        <td class="border texto-cen">{{ item.marca }}</td>
                                        <td class="border texto-cen">{{ item.medida }}</td>
                                        <td class="border texto-cen">{{ item.modelo }}</td>
                                        <td class="border texto-cen">{{ item.estado }}</td>
                                        <td class="border texto-cen">{{ item.disenio }}</td>
                                        <td class="border texto-cen">{{ item.serieneumatico }}</td>
                                        <td class="border texto-cen">{{ item.nsd }}</td>
                                        <td class="border texto-cen">{{ item.observaciones }}</td>
                                        <td class="border texto-cen">
                                            <img :src="ruta_image + item.ruta1" alt="" width="70px" height="70px"
                                                v-if="item.ruta1 != null" />
                                                <span v-else>-</span>
                                        </td>
                                    </tr>
                                    <tr v-if="NeumaticosMalEstado.length == 0">
                                        <td class="border texto-cen" colspan="12">
                                            No hay neumáticos en mal estado
                                        </td>
                                    </tr>
                                </table>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Resumen de neumáticos en scrap-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Resumen de Neumáticos En Scrap
                                <v-spacer> </v-spacer>
                                <v-btn style="margin-left: 2px" height="22" width="22" color="green" fab x-small dark
                                    @click="exportarAExcelScrapxMotivo()" :loading="loadingExcelScrap">
                                    <v-icon x-small>mdi-file-excel-outline</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-container class="pa-4">
                                <table class="letra-10" style="width: 100%">
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen" style="width: 50%">
                                            <strong>
                                                <font color="white">Motivo retiro</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 25%">
                                            <strong>
                                                <font color="white">Cantidad</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 24%">
                                            <strong>
                                                <font color="white">Porcentaje</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in NeumaticosScrap" :key="index">
                                        <td class="border texto-cen">{{ item.motivo }}</td>
                                        <td class="border texto-cen">
                                            {{ item.cantidad }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{ item.porcentaje }} %
                                        </td>
                                    </tr>
                                    <tr v-if="NeumaticosScrap.length > 0">
                                        <td class="no-border-left no-border-bottom texto-cen"></td>
                                        <td class="border texto-cen">
                                            {{ calcularCantidadNeumaticosScrap() }}
                                        </td>
                                        <td class="border texto-cen">
                                            100 %
                                        </td>
                                    </tr>
                                    <tr v-if="NeumaticosScrap.length == 0">
                                        <td class="border texto-cen" colspan="3">
                                            No hay neumáticos en scrap
                                        </td>
                                    </tr>
                                </table>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Resumen de neumáticos en retiro-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Resumen de Neumáticos En Retiro
                            </v-toolbar>
                            <v-container class="pa-4">
                                <table class="letra-10" style="width: 100%" id="table">
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen" style="width: 8%">
                                            <strong>
                                                <font color="white">Vehículo</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 5%">
                                            <strong>
                                                <font color="white">POS</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Marca</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Medidas</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Diseño</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Estado</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Serie</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">1</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">2</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">NSD</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Recomendación</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 10%">
                                            <strong>
                                                <font color="white">Imagen</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in NeumaticosRetiro" :key="index">
                                        <td class="border texto-cen">{{ item.placa }}</td>
                                        <td class="border texto-cen">{{ item.posicion }}</td>
                                        <td class="border texto-cen">{{ item.marca }}</td>
                                        <td class="border texto-cen">{{ item.medida }}</td>
                                        <td class="border texto-cen">{{ item.disenio }}</td>
                                        <td class="border texto-cen">{{ item.estado }}</td>
                                        <td class="border texto-cen">{{ item.num_serie }}</td>
                                        <td class="border texto-cen">{{ item.exterior }}</td>
                                        <td class="border texto-cen">{{ item.interior }}</td>
                                        <td class="border texto-cen">{{ item.nsd }}</td>
                                        <td class="border texto-cen">{{ item.recomendacion }}</td>
                                        <td class="border texto-cen">
                                            <img :src="ruta_image + item.imagen1" alt="" width="70px"
                                                height="70px" />
                                        </td>
                                    </tr>
                                    <tr v-if="NeumaticosRetiro.length == 0">
                                        <td class="border texto-cen" colspan="12">
                                            No hay neumáticos en retiro
                                        </td>
                                    </tr>
                                </table>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Niveles de remanente por unidad-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Niveles de remanente por unidad
                            </v-toolbar>
                            <v-container class="pa-4">
                                <table class="table-responsive letra-10" style="width: 100%" id="table">
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen pa-2" style="width: 25%" colspan="2"></td>
                                        <td class="border texto-cen pa-2" style="width: 55%"
                                            :colspan="NivelesRemanenteUnidadPosiciones.length">
                                            <strong>
                                                <font color="white">Posiciones</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen pa-2" style="width: 7%"></td>
                                        <td class="border texto-cen pa-2" style="width: 25%" colspan="2">
                                            <strong>
                                                <font color="white">Para reemplazar</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen pa-1" style="width: 8%">
                                            <strong>
                                                <font color="white">Vehículo</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen pa-1 pl-5 pr-5" style="width: 8%">
                                            <strong>
                                                <font color="white">Placa</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen pa-1" style="width: 3%"
                                            v-for="(item, index) in NivelesRemanenteUnidadPosiciones" :key="index">
                                            <strong>
                                                <font color="white">{{ item }}</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen pa-1" style="width: 5%">
                                            <strong>
                                                <font color="white">Total</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen pa-1" style="width: 5%">
                                            <strong>
                                                <font color="white">Reencauchar </font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen pa-1" style="width: 5%">
                                            <strong>
                                                <font color="white">Próx. a Reenc.</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in NivelesRemanenteUnidad" :key="index">
                                        <td class="border texto-cen pa-1">
                                            {{ item.tipovehiculo }}
                                        </td>
                                        <td class="border texto-cen pa-1">{{ item.placa }}</td>

                                        <td v-for="(item2, index2) in NivelesRemanenteUnidadPosiciones" :key="index2"
                                            :class="tdPintar(tdValorPosicion(index, item2),item.rem_reencauche,item.rem_proximo)">
                                            {{ tdValorPosicion(index, item2) }}
                                        </td>

                                        <td class="border texto-cen pa-1">
                                            {{ item.total_neumaticos }}
                                        </td>
                                        <td class="border texto-cen bg-celda-amarilla pa-1">
                                            {{ item.neumaticos_reencauchar }}
                                        </td>
                                        <td class="border texto-cen bg-celda-azul pa-1">
                                            {{ item.neumaticos_proximos_reencauchar }}
                                        </td>
                                    </tr>
                                    <tr v-if="NivelesRemanenteUnidad.length == 0">
                                        <td class="border texto-cen pa-1" colspan="5">
                                            No hay datos para remanentes por unidad
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td class="border pa-2" rowspan="2"
                                            :colspan="NivelesRemanenteUnidadPosiciones.length + 2"><strong>Total</strong>
                                        </td>
                                        <td class="border texto-cen pa-2" rowspan="2"> {{
                                            ResumenRemanentesUnidad.total_neumaticos }}</td>
                                        <td class="border texto-cen pa-2">
                                            {{ ResumenRemanentesUnidad.total_neumaticos_reencauchar }}
                                        </td>
                                        <td class="border texto-cen pa-2">
                                            {{ ResumenRemanentesUnidad.neumaticos_proximos_reencauchar }}</td>
                                    </tr>
                                    <tr>
                                        <td class="border texto-cen pa-2">
                                            {{
                                                calcularPorcentajes(
                                                    ResumenRemanentesUnidad.total_neumaticos_reencauchar,
                                                    ResumenRemanentesUnidad.total_neumaticos
                                                )
                                            }}
                                        </td>
                                        <td class="border texto-cen pa-2">
                                            {{
                                                calcularPorcentajes(
                                                    ResumenRemanentesUnidad.neumaticos_proximos_reencauchar,
                                                    ResumenRemanentesUnidad.total_neumaticos
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </table>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Neumáticos para servicio de reencauche-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Neumáticos para servicio de reencauche
                            </v-toolbar>
                            <v-container class="pa-4">
                                <div class="table-responsive">
                                    <table class="letra-10" style="width: 100%" id="table">
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Vehículo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 7%">
                                                <strong>
                                                    <font color="white">Pos</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Marca</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Medida</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Modelo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 7%">
                                                <strong>
                                                    <font color="white">Estado</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Serie</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">1</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">2</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">3</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">NSD</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Observaciones</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr v-for="(item, index) in NeumaticosServicioReencauche" :key="index">
                                            <td class="border texto-cen">{{ item.placa }}</td>
                                            <td class="border texto-cen">{{ item.posicion }}</td>
                                            <td class="border texto-cen">{{ item.marca }}</td>
                                            <td class="border texto-cen">{{ item.medida }}</td>
                                            <td class="border texto-cen">{{ item.modelo }}</td>
                                            <td class="border texto-cen">{{ item.estado }}</td>
                                            <td class="border texto-cen">{{ item.num_serie }}</td>
                                            <td class="border texto-cen">{{ item.interior }}</td>
                                            <td class="border texto-cen">{{ item.medio }}</td>
                                            <td class="border texto-cen">{{ item.exterior }}</td>
                                            <td class="border texto-cen">{{ item.nsd }}</td>
                                            <td class="border texto-cen">{{ item.recomendacion }}</td>
                                        </tr>
                                        <tr v-if="NeumaticosServicioReencauche.length == 0">
                                            <td class="border texto-cen" colspan="12">
                                                No hay neumáticos para servicio de reencauche
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Rotaciones de desgastes irregulares-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Rotaciones - Desgastes irregulares
                            </v-toolbar>
                            <v-container class="pa-4">
                                <div class="table-responsive">
                                    <table class="letra-10" style="width: 100%" id="table">
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Vehículo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 7%">
                                                <strong>
                                                    <font color="white">Pos</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Marca</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Medida</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Modelo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Estado</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Serie</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">1</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">2</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">NSD</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 9%">
                                                <strong>
                                                    <font color="white">Observaciones</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr v-for="(item, index) in RotacionesIrregulares" :key="index">
                                            <td class="border texto-cen">{{ item.placa }}</td>
                                            <td class="border texto-cen">{{ item.posicion }}</td>
                                            <td class="border texto-cen">{{ item.marca }}</td>
                                            <td class="border texto-cen">{{ item.medida }}</td>
                                            <td class="border texto-cen">{{ item.modelo }}</td>
                                            <td class="border texto-cen">{{ item.estado }}</td>
                                            <td class="border texto-cen">{{ item.num_serie }}</td>
                                            <td class="border texto-cen">{{ item.interior }}</td>
                                            <td class="border texto-cen">{{ item.exterior }}</td>
                                            <td class="border texto-cen">{{ item.nsd }}</td>
                                            <td class="border texto-cen">{{ item.recomendacion }}</td>
                                        </tr>
                                        <tr v-if="RotacionesIrregulares.length == 0">
                                            <td class="border texto-cen" colspan="11">
                                                No hay neumáticos con desgaste irregular
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Inflado de neumáticos-->
                <v-row justify="center">
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Inflado de neumáticos
                            </v-toolbar>
                            <v-container class="pa-4">
                                <div class="text-center">
                                    <div>
                                        <canvas ref="elemento" id="pieInflado" style="max-height: 400px"></canvas>
                                    </div>
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Presión de inflado de neumáticos
                            </v-toolbar>
                            <v-container class="pa-4">
                                <canvas ref="elemento" id="piePresion" style="max-height: 400px"></canvas>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Neumáticos remanentes medida-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Niveles de remanente por medida
                            </v-toolbar>
                            <v-container class="pa-4" v-for="(item, index1) in NivelesRemanenteMedidaoficial" :key="index1">
                                <table class="letra-10 mt-4" style="width: 100%">
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen" colspan="2">
                                            <strong>
                                                <font color="white">Aplicación: {{ ResumenRemanentesMedida[index1].aplicacion.aplicacion }}</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" :colspan="RemanentesMedida[index1].length + 1">
                                            <strong>
                                                <font color="white">PROFUNDIDAD DE BANDA DE RODAMIENTO EN
                                                    MILÍMETROS</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen pa-2" style="width: 15%">
                                            <strong>
                                                <font color="white">Vehículo</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen pa-2" style="width: 15%">
                                            <strong>
                                                <font color="white">Medida</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 50px !important"
                                            v-for="(item, index) in RemanentesMedida[index1]" :key="index">
                                            <strong>
                                                <font color="white">{{ item }}</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen pa-2" style="min-width: 15%">
                                            <strong>
                                                <font color="white">Total</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in NivelesRemanenteMedida[index1]" :key="index">
                                        <td class="border texto-cen">{{ item.tipovehiculo }}</td>
                                        <td class="border texto-cen">{{ item.medida }}</td>

                                        <td v-for="(item2, index2) in RemanentesMedida[index1]" :key="index2"
                                            :class="tdPintarRemanente(item2,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche,ResumenRemanentesMedida[index1].aplicacion.rem_proximo)">
                                            {{ tdValorRemanente(index1,index, item2) }}
                                        </td>

                                        <td class="border texto-cen">
                                            {{ item.total_vehiculo_medidas }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="border texto-cen">Total</td>
                                        <td class="border texto-cen" v-for="(item, index) in TotalesMedida[index1]" :key="index">
                                            {{ item }}
                                        </td>
                                        <td class="border texto-cen">{{ TotalDatosMedida[index1] }}</td>
                                    </tr>
                                    <tr v-if="colspanRemParaReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche)!=0 || colspanRemProxReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche,ResumenRemanentesMedida[index1].aplicacion.rem_proximo)!=0">
                                        <td class="border texto-cen" colspan="2" rowspan="2"></td>
                                        <td class="border texto-cen" v-if="colspanRemParaReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche)!=0" :colspan="colspanRemParaReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche)">
                                            {{ ResumenRemanentesMedida[index1].neumaticos_reencauchar }}
                                        </td>
                                        <td class="border texto-cen" v-if="colspanRemProxReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche,ResumenRemanentesMedida[index1].aplicacion.rem_proximo)!=0" :colspan="colspanRemProxReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche,ResumenRemanentesMedida[index1].aplicacion.rem_proximo)">
                                            {{
                                                ResumenRemanentesMedida[index1].neumaticos_proximo_reencauchar
                                            }}
                                        </td>
                                        <td class="border texto-cen" v-if="(RemanentesMedida[index1].length - colspanRemParaReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche))!=0" rowspan="2"
                                            :colspan="RemanentesMedida[index1].length - colspanRemParaReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche)"></td>
                                    </tr>
                                    <tr v-if="colspanRemParaReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche)!=0 || colspanRemProxReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche,ResumenRemanentesMedida[index1].aplicacion.rem_proximo)!=0">
                                        <td class="border texto-cen" v-if="colspanRemParaReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche)!=0" :colspan="colspanRemParaReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche)">
                                            {{
                                                calcularPorcentajes(
                                                    ResumenRemanentesMedida[index1].neumaticos_reencauchar,
                                                    ResumenRemanentesMedida[index1].total_general
                                                )
                                            }}
                                        </td>
                                        <td class="border texto-cen" v-if="colspanRemProxReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche,ResumenRemanentesMedida[index1].aplicacion.rem_proximo)!=0" :colspan="colspanRemProxReencauche(index1,ResumenRemanentesMedida[index1].aplicacion.rem_reencauche,ResumenRemanentesMedida[index1].aplicacion.rem_proximo)">
                                            {{
                                                calcularPorcentajes(
                                                    ResumenRemanentesMedida[index1].neumaticos_proximo_reencauchar,
                                                    ResumenRemanentesMedida[index1].total_general
                                                )
                                            }}
                                        </td> 
                                    </tr>
                                </table> 
                                <table class="letra-10 mt-4" style="width: 100%">
                                    <tr style="background-color: #6d6b6b">
                                        <td class="border texto-cen" style="width: 15%">
                                            <strong>
                                                <font color="white">Aplicación</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 15%">
                                            <strong>
                                                <font color="white">Reenc.</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 15%">
                                            <strong>
                                                <font color="white">Próx. Reenc.</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 15%">
                                            <strong>
                                                <font color="white">Operativo</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 15%">
                                            <strong>
                                                <font color="white">Total</font>
                                            </strong>
                                        </td>
                                        <td class="border texto-cen" style="width: 15%">
                                            <strong>
                                                <font color="white">Porcentaje</font>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="border texto-cen">{{ResumenRemanentesMedida[index1].aplicacion.aplicacion}}</td>
                                        <td class="border texto-cen">
                                            {{ ResumenRemanentesMedida[index1].neumaticos_reencauchar }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{
                                                ResumenRemanentesMedida[index1].neumaticos_proximo_reencauchar
                                            }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{ ResumenRemanentesMedida[index1].neumaticos_operativos }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{ ResumenRemanentesMedida[index1].total_general }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{ mostrarPorcentaje(NivelesRemanenteMedida[index1]) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="border texto-cen">Total</td>
                                        <td class="border texto-cen">
                                            {{
                                                calcularPorcentajes(
                                                    ResumenRemanentesMedida[index1].neumaticos_reencauchar,
                                                    ResumenRemanentesMedida[index1].total_general
                                                )
                                            }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{
                                                calcularPorcentajes(
                                                    ResumenRemanentesMedida[index1].neumaticos_proximo_reencauchar,
                                                    ResumenRemanentesMedida[index1].total_general
                                                )
                                            }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{
                                                calcularPorcentajes(
                                                    ResumenRemanentesMedida[index1].neumaticos_operativos,
                                                    ResumenRemanentesMedida[index1].total_general
                                                )
                                            }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{ ResumenRemanentesMedida[index1].total_general }}
                                        </td>
                                        <td class="border texto-cen">
                                            {{ mostrarPorcentaje(NivelesRemanenteMedida[index1]) }}
                                        </td>
                                    </tr>
                                </table>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Indice de reencauche y reencauchabilidad-->
                <v-row justify="center">
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Indicador de reencauche
                            </v-toolbar>
                            <v-container class="pa-4">
                                <div class="text-center">
                                    <div>
                                        <canvas ref="elemento" id="pieReencauche" style="max-height: 400px"></canvas>
                                    </div>
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col lg="6">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Indicador de reencauchabilidad
                            </v-toolbar>
                            <v-container class="pa-4">
                                <canvas ref="elemento" id="barReencauchabilidad" style="max-height: 400px"></canvas>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Inspecciones-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Inspecciones
                            </v-toolbar>
                            <v-container class="pa-4">
                                <div class="table-responsive" :class="tamanioTabla()" v-for="(item, index) in Inspecciones"
                                    :key="index">
                                    <table class="letra-10" style="width: 100%" id="table">
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen" style="width: 50%" colspan="4">
                                                <strong>
                                                    <font color="white">Fecha de inspección:
                                                        {{ item.fecha_inspeccion }}</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" colspan="3">
                                                <strong>
                                                    <font color="white">Km. Inspección: {{ item.km_inspeccion }}</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" colspan="4">
                                                <strong>
                                                    <font color="white">Tipo: {{ item.tipovehiculo }}</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" colspan="5">
                                                <strong>
                                                    <font color="white">Marca de vehículo: {{ item.marcavehiculo }}</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" colspan="4">
                                                <strong>
                                                    <font color="white">Placa de vehículo: {{ item.placa }}</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen" style="width: 20%" rowspan="2">
                                                <strong>
                                                    <font color="white">Pos</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" rowspan="2">
                                                <strong>
                                                    <font color="white">Marca de neumático</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" rowspan="2">
                                                <strong>
                                                    <font color="white">Medida</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" rowspan="2">
                                                <strong>
                                                    <font color="white">Modelo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 10%" rowspan="2">
                                                <strong>
                                                    <font color="white">Estado</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 10%" rowspan="2">
                                                <strong>
                                                    <font color="white">Diseño de reencauche</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 70%" rowspan="2">
                                                <strong>
                                                    <font color="white">Empresa reencauchadora </font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" rowspan="2">
                                                <strong>
                                                    <font color="white">Serie</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 79%" colspan="3">
                                                <strong>
                                                    <font color="white">Válvula</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 40%" colspan="2">
                                                <strong>
                                                    <font color="white">Presión</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 10%" rowspan="2">
                                                <strong>
                                                    <font color="white">Estado</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 10%" colspan="5">
                                                <strong>
                                                    <font color="white">NSD</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%" rowspan="2">
                                                <strong>
                                                    <font color="white">Recomendación</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen" style="width: 20%">
                                                <strong>
                                                    <font color="white">Tapa</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%">
                                                <strong>
                                                    <font color="white">Inacc.</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%">
                                                <strong>
                                                    <font color="white">Malog.</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 10%">
                                                <strong>
                                                    <font color="white">Actual</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 10%">
                                                <strong>
                                                    <font color="white">Recom.</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 10%">
                                                <strong>
                                                    <font color="white">Orig.</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%">
                                                <strong>
                                                    <font color="white">1</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="min-width: 20%">
                                                <strong>
                                                    <font color="white">2</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 20%">
                                                <strong>
                                                    <font color="white">3</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 10%">
                                                <strong>
                                                    <font color="white">NSD Min</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr v-for="(item, index) in item.detalle" :key="index">
                                            <td class="border texto-cen pa-2">{{ item.posicion }}</td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.marcaneumatico }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.medidaneumatico }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.modeloneumatico }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.estadoneumatico }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.disenioneumatico }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.razon_social }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.num_serie }}
                                            </td>
                                            <td class="border texto-cen pa-2">{{ item.valvula }}</td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.accesibilidad }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.malogrado }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.presionactual }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.presion_recomendada }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.estadopresion }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.remanente_original }}
                                            </td>
                                            <td class="border texto-cen pa-2">{{ item.exterior }}</td>
                                            <td class="border texto-cen pa-2">{{ item.medio }}</td>
                                            <td class="border texto-cen pa-2">{{ item.interior }}</td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.nsk_minimo }}
                                            </td>
                                            <td class="border texto-cen pa-2">
                                                {{ item.recomendacion }}
                                            </td>
                                        </tr>
                                        <tr v-if="item.detalle.length == 0">
                                            <td class="border texto-cen" colspan="11">
                                                No hay neumáticos inspeccionados
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Costo por kilometros-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Costo por kilómetro
                            </v-toolbar>
                            <v-container class="pa-4">
                                <IndicadorCosto :CostoPorKilometro="CostoPorKilometro"
                                    :MesesCostoPorKilometro="MesesCostoPorKilometro" />
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!--Perdidas por scrap-->
                <v-row justify="center">
                    <v-col lg="12">
                        <v-card>
                            <v-toolbar height="60" color="#545554" dark>
                                Pérdidas por scrap
                            </v-toolbar>
                            <v-container class="pa-4">
                                <div class="table-responsive">
                                    <table class="letra-10" style="width: 100%" id="table">
                                        <tr style="background-color: #6d6b6b">
                                            <td class="border texto-cen" style="width: 7%">
                                                <strong>
                                                    <font color="white">N. de serie</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 7%">
                                                <strong>
                                                    <font color="white">Serie del cliente</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 7%">
                                                <strong>
                                                    <font color="white">Marca</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 7%">
                                                <strong>
                                                    <font color="white">Modelo</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 7%">
                                                <strong>
                                                    <font color="white">Medida</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Condición de neumático</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Fecha de scrap</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Motivo scrap</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Remanente original</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Remanente final</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Remanente límite</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Costo ($)</font>
                                                </strong>
                                            </td>
                                            <td class="border texto-cen" style="width: 8%">
                                                <strong>
                                                    <font color="white">Costo de pérdida ($)</font>
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr v-for="(item, index) in PerdidasPorScrap" :key="index">
                                            <td class="border texto-cen">{{ item.num_serie }}</td>
                                            <td class="border texto-cen">{{ item.serie_cliente }}</td>
                                            <td class="border texto-cen">{{ item.marca }}</td>
                                            <td class="border texto-cen">{{ item.modelo }}</td>
                                            <td class="border texto-cen">{{ item.medida }}</td>
                                            <td class="border texto-cen">{{ item.condicion }}</td>
                                            <td class="border texto-cen">{{ item.fecha_scrap }}</td>
                                            <td class="border texto-cen">{{ item.motivo }}</td>
                                            <td class="border texto-cen">
                                                {{ item.remanente_original }}
                                            </td>
                                            <td class="border texto-cen">
                                                {{ item.remanente_final }}
                                            </td>
                                            <td class="border texto-cen">
                                                {{ item.remanente_limite }}
                                            </td>
                                            <td class="border texto-cen">{{ item.costo }}</td>
                                            <td class="border texto-cen">{{ item.costo_perdido }}</td>
                                        </tr>
                                        <tr v-if="PerdidasPorScrap.length == 0">
                                            <td class="border texto-cen" colspan="13">
                                                No hay datos para pérdidas por scrap
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card class="pa-5">
                    <v-card-title>
                        <span class="text-h5">Desea eliminar esta recomendación ? </span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea autocomplete="recomendacion" label="Recomendación"
                                        v-model="recomendacion.descripcion" readonly rows="2"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="
                            dialog = false;
                        recomendacion = {};
                        ">
                            Cerrar
                        </v-btn>
                        <v-btn color="info" @click="confirmar()"> Eliminar </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPDF" persistent max-width="85%" style="height: 100vh !important;">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Reporte Consolidado (PDF) </span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row justify="center">
                                <vue-loaders v-if="loaderpdf == true" name="ball-beat" color="red" scale="1"></vue-loaders>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" v-if="ver_iframe == true">
                                    <iframe @load="loaderpdf = false" id="ifrm" :src="endpoint + 'reporte/pdf/' + pdf.uuid"
                                        style="width: 100% !important; height: 70vh !important; border: none !important;"></iframe>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrarVisor"> Cerrar </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
import Chart from "chart.js/auto";
import chartTrendline from "chartjs-plugin-trendline";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(chartTrendline);
Chart.register(ChartDataLabels);
import axios from "axios";
import global from "../../global";
import IndicadorCosto from "../../components/indicador/tabla.vue"

export default {
    components: {
        IndicadorCosto
    },
    data() {
        return {
            ruta_image: global.ruta_image,
            Item: {},
            visualizarReporte: false,
            loader: false,
            meses: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ],
            recomendacion: {
                id: null,
                recomendacion: "",
            },
            nombreusuario: "",
            rutaImagenFirma: "",
            dialog: false,
            dialogPDF: false,
            buttonExportar: true,
            endpoint: "",
            pdf: "",
            loaderpdf: false,
            ver_iframe: false,
            valid: false,
            loadingExcelScrap: false
        };
    },
    computed: {
        ...mapState("reporteconsolidado", [
            "Cliente",
            "Usuario",
            "EstadoReporte",
            "Years",
            "Months",
            "Recomendaciones",
            "EquiposInspeccionados",
            "DistribucionMedidasNeumaticos",
            "PosicionRuedaSegunMarca",
            "DistribucionEjeDireccional",
            "DistribucionEjeTraccion",
            "DistribucionEjeLibre",
            "NeumaticosMalEstado",
            "NeumaticosScrap",
            "NeumaticosRetiro",
            "NivelesRemanenteUnidad",
            "ResumenRemanentesUnidad",
            "NivelesRemanenteUnidadPosiciones",
            "AplicacionesRemanenteUnidad",
            "NeumaticosServicioReencauche",
            "RotacionesIrregulares",
            "InfladoNeumaticos",
            "PresionNeumaticos",
            "NivelesRemanenteMedidaoficial",
            "NivelesRemanenteMedida",
            "ResumenRemanentesMedida",
            "RemanentesMedida",
            "TotalesMedida",
            "TotalDatosMedida",
            "IndiceReencauche",
            "IndiceReencauchabilidad",
            "Inspecciones",
            "CostoPorKilometro",
            "PerdidasPorScrap",
            "MesesCostoPorKilometro",
            "AplicacionNivelesRemanentes",
        ]),

        ...mapState("navigation", ["Mini"]),
    },
    methods: {
        ...mapActions("reporteconsolidado", [
            "cargarAnios",
            "cargarMeses",
            "cargarDatosReporteConsolidado",
            "saveRecomendacion",
            "deleteRecomendacion",
        ]),

        onMeses: function (anio) {
            this.Item.month1 = null;
            this.Item.month2 = null;
            this.$refs.form.resetValidation();
            this.cargarMeses(anio);
        },

        consultarDatos: async function () {
            this.visualizarReporte = false;
            await this.$nextTick();
            this.loader = true;
            if (parseInt(this.Item.month1) <= parseInt(this.Item.month2)) {
                this.visualizarReporte = true;
                await this.cargarDatosReporteConsolidado(this.Item);
                this.graficoDistribucionMedidas();
                this.graficoPosicionRuedaSegunMarca();
                this.graficoDistribucionMarcasEjeDireccional();
                this.graficoDistribucionMarcasEjeTraccion();
                this.graficoDistribucionMarcasEjeLibre();
                this.graficoInfladoNeumaticos();
                this.graficoPresionNeumaticos();
                this.graficoIndiceReencauche();
                this.graficoIndiceReencauchabilidad();
                this.buttonExportar = false;
                this.loader = false;
            } else {
                this.loader = false;
                this.$alertify.error(
                    "Por favor, ingresar un mes igual o mayor al primer mes seleccionado"
                );
            }
        },

        graficoPie: function (id_grafico, labels, title, data) {
            var popCanvas = document.getElementById(id_grafico);
            new Chart(popCanvas, {
                plugins: [ChartDataLabels],
                type: "pie",
                data: {
                    labels: labels,
                    datasets: [{
                        label: "My First Dataset",
                        data: data,
                        backgroundColor: [
                            "rgb(255, 99, 132)",
                            "rgb(27, 205, 249)",
                            "rgb(255, 205, 86)",
                            "rgb(218, 247, 166)",
                            "rgb(27, 216, 176)",
                            "rgb(253, 152, 37)",
                            "rgb(254, 243, 80 )",
                            "rgb(179, 241, 238)",
                            "rgb(125, 254, 80)",
                            "rgb(54, 162, 235)",
                        ],
                        hoverOffset: 4,
                    },],
                },
                options: {
                    //indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: true,
                            position: "right",
                            labels: {
                                color: "black",
                                font: {
                                    size: 11,
                                },
                            },
                        },
                        datalabels: {
                            /* anchor puede ser "start", "center" o "end" */
                            anchor: "center",
                            /* Podemos modificar el texto a mostrar */
                            //formatter: (dato) => dato + "%",
                            formatter: (dato) => dato,
                            /* Color del texto */
                            color: "black",
                            /* Formato de la fuente */
                            font: {
                                family: '"Times New Roman", Times, serif',
                                size: "10",
                                weight: "bold",
                            },
                            /* Formato de la caja contenedora */
                            padding: "4",
                            borderWidth: 2,
                            //borderColor: "darkblue",
                            borderRadius: 8,
                            backgroundColor: "white",
                        },
                    },
                    tooltips: {
                        yAlign: "bottom",
                        xAlign: "center",
                        xPadding: 25,
                        yPadding: 15,
                        xPadding: 45,
                        _bodyAlign: "center",
                        _footerAlign: "center",
                        backgroundColor: "#ccc",
                        titleFontSize: 16,
                        titleFontColor: "#0066ff",
                        bodyFontColor: "#000",
                        bodyFontSize: 14,
                        displayColors: false,
                    },
                },
            });
        },

        graficoBar: function (id_grafico, labels, title, data) {
            var popCanvas = document.getElementById(id_grafico);
            new Chart(popCanvas, {
                plugins: [ChartDataLabels],
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [{
                        label: "Neumáticos",
                        data: data,
                        backgroundColor: ["rgb(27, 205, 249)"],
                        hoverOffset: 4,
                    },],
                },
                options: {
                    indexAxis: "y",
                    scales: {
                        x: {
                            beginAtZero: true, // Asegura que el eje X (eje horizontal en un gráfico de barras horizontales) comience desde 0
                            suggestedMin: 0, // Asegura que el gráfico comience desde 0 en el eje X
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                            position: "right",
                            labels: {
                                color: "black",
                                font: {
                                    size: 11,
                                },
                            },
                        },
                        datalabels: {
                            /* anchor puede ser "start", "center" o "end" */
                            anchor: "center",
                            /* Podemos modificar el texto a mostrar */
                            //formatter: (dato) => dato + "%",
                            formatter: (dato) => dato,
                            /* Color del texto */
                            color: "black",
                            /* Formato de la fuente */
                            font: {
                                family: '"Times New Roman", Times, serif',
                                size: "10",
                                weight: "bold",
                            },
                            /* Formato de la caja contenedora */
                            //padding: "4",
                            //borderWidth: 2,
                            //borderColor: "darkblue",
                            //borderRadius: 8,
                            //backgroundColor: "white"
                        },
                    },
                    tooltips: {
                        yAlign: "bottom",
                        xAlign: "center",
                        xPadding: 25,
                        yPadding: 15,
                        xPadding: 45,
                        _bodyAlign: "center",
                        _footerAlign: "center",
                        backgroundColor: "#ccc",
                        titleFontSize: 16,
                        titleFontColor: "#0066ff",
                        bodyFontColor: "#000",
                        bodyFontSize: 14,
                        displayColors: false,
                    },
                },
            });
        },

        graficoDistribucionMedidas: function () {
            let etiquetas = [];
            let data = [];
            this.DistribucionMedidasNeumaticos.forEach((element) => {
                let descripcion =
                    element.descripcion + " (" + element.porcentaje + "%) ";
                etiquetas.push(descripcion);
                data.push(element.count_medida);
            });
            this.graficoPie("pieMedidas", etiquetas, "", data);
        },

        graficoPosicionRuedaSegunMarca: function () {
            let etiquetas = [];
            let data = [];
            this.PosicionRuedaSegunMarca.forEach((element) => {
                let descripcion =
                    element.desc_marca + " (" + element.porcentaje + "%) ";
                etiquetas.push(descripcion);
                data.push(element.cantidad_marca);
            });
            this.graficoPie("pieMarcas", etiquetas, "", data);
        },

        graficoDistribucionMarcasEjeDireccional: function () {
            let etiquetas = [];
            let data = [];
            this.DistribucionEjeDireccional.forEach((element) => {
                //let descripcion =
                //element.desc_marca + " (" + element.porcentaje + "%) ";
                etiquetas.push(element.desc_marca);
                data.push(element.cantidad_marca);
            });
            if (this.DistribucionEjeDireccional.length > 0)
                this.graficoBar("barDireccional", etiquetas, "", data);
        },

        graficoDistribucionMarcasEjeTraccion: function () {
            let etiquetas = [];
            let data = [];
            this.DistribucionEjeTraccion.forEach((element) => {
                //let descripcion =
                //element.desc_marca + " (" + element.porcentaje + "%) ";
                etiquetas.push(element.desc_marca);
                data.push(element.cantidad_marca);
            });
            if (this.DistribucionEjeTraccion.length > 0)
                this.graficoBar("barTraccion", etiquetas, "", data);
        },

        graficoDistribucionMarcasEjeLibre: function () {
            let etiquetas = [];
            let data = [];
            this.DistribucionEjeLibre.forEach((element) => {
                //let descripcion =
                //element.desc_marca + " (" + element.porcentaje + "%) ";
                etiquetas.push(element.desc_marca);
                data.push(element.cantidad_marca);
            });
            if (this.DistribucionEjeLibre.length > 0)
                this.graficoBar("barLibre", etiquetas, "", data);
        },

        tdPintar: function (nsk_minimo,rem_reencauche,rem_proximo) {
            console.log(rem_reencauche,rem_proximo,'item2');
            let rem_proximo_aru =
                parseFloat(rem_proximo) ?? 0;
            let rem_reencauche_aru =
                parseFloat(rem_reencauche) ?? 0;

            let pintar = "";
            if (nsk_minimo != "-" && nsk_minimo) {
                if (
                    nsk_minimo <= rem_proximo_aru + 1 &&
                    nsk_minimo > rem_reencauche_aru
                ) {
                    pintar = "border texto-cen bg-celda-azul pa-1";
                } else if (nsk_minimo <= rem_reencauche_aru) {
                    pintar = "border texto-cen bg-celda-amarilla pa-1";
                } else {
                    pintar = "border texto-cen pa-1";
                }
            } else {
                pintar = "border texto-cen pa-1";
            }
            return pintar;
        },

        tdPintar: function (nsk_minimo,rem_reencauche,rem_proximo) {
            console.log(rem_reencauche,rem_proximo,'item2');
            let rem_proximo_aru =
                parseFloat(rem_proximo) ?? 0;
            let rem_reencauche_aru =
                parseFloat(rem_reencauche) ?? 0;

            let pintar = "";
            if (nsk_minimo != "-" && nsk_minimo) {
                if (
                    nsk_minimo <= rem_proximo_aru + 1 &&
                    nsk_minimo > rem_reencauche_aru
                ) {
                    pintar = "border texto-cen bg-celda-azul pa-1";
                } else if (nsk_minimo <= rem_reencauche_aru) {
                    pintar = "border texto-cen bg-celda-amarilla pa-1";
                } else {
                    pintar = "border texto-cen pa-1";
                }
            } else {
                pintar = "border texto-cen pa-1";
            }
            return pintar;
        },


        tdPintarRemanente: function (pos,rem_reencauche,rem_proximo) {
            let pintar = this.tdPintar(pos,rem_reencauche,rem_proximo);
            return pintar;
        },

        pintarCostoPorKilometro: function (item, valor) {
            let pintar = "border texto-cen";
            if (valor) {
                if (valor >= item.rango_c_inferior && valor <= item.rango_c_superior)
                    pintar = "border texto-cen bg-celda-amarilla";
                if (valor < item.criterio_superior)
                    pintar = "border texto-cen bg-celda-verde";
                if (valor > item.criterio_inferior)
                    pintar = "border texto-cen bg-celda-roja";
            }
            return pintar;
        },

        tdValorPosicion: function (index, item2) {
            return this.NivelesRemanenteUnidad[index]["nsk_minimo_" + item2];
        },

        tdValorCosto: function (index, item2) {
            return this.CostoPorKilometro[index]["indicador_mes_" + item2];
        },

        tdValorRemanente: function (index1,index, item2) {
            return this.NivelesRemanenteMedida[index1][index][
                "totalneumaticos_nsk_minimo_" + item2
            ];
        },

        graficoInfladoNeumaticos: function () {
            let etiquetas = [];
            let data = [];
            this.InfladoNeumaticos.forEach((element) => {
                let descripcion =
                    element.descripcion + " (" + element.porcentaje + "%) ";
                etiquetas.push(descripcion);
                data.push(element.cantidad);
            });
            this.graficoPie("pieInflado", etiquetas, "", data);
        },

        graficoPresionNeumaticos: function () {
            let etiquetas = [];
            let data = [];
            this.PresionNeumaticos.forEach((element) => {
                let descripcion =
                    element.descripcion + " (" + element.porcentaje + "%) ";
                etiquetas.push(descripcion);
                data.push(element.cantidad);
            });
            this.graficoPie("piePresion", etiquetas, "", data);
        },

        nameAplicacionRemanente: function () {
            const value = this.AplicacionNivelesRemanentes.length > 0 ? this.AplicacionNivelesRemanentes[0]['nombre_aplicacion'] : 0;

            if (value === 0) {
                return 0;
            }

            return value;
        },

        colspanRemParaReencauche: function (index1,rem_reencauche) {
            const rmParaReenc = this.AplicacionNivelesRemanentes.length > 0 ? parseInt(rem_reencauche) : 0;

            if (rmParaReenc === 0) {
                return 0;
            }

            const value = this.RemanentesMedida[index1].reduce((count, element) => {
                return count + (element <= rmParaReenc ? 1 : 0);
            }, 0);

            // console.log('value RParaR> ', value);
            return value;
        },

        colspanRemProxReencauche: function (index1,rem_reencauche,rem_proximo) {
            const rmParaReenc = this.AplicacionNivelesRemanentes.length > 0 ? parseInt(rem_reencauche) : 0;
            const rmProxReenc = this.AplicacionNivelesRemanentes.length > 0 ? parseInt(rem_proximo) : 0;
            if (rmParaReenc === 0 || rmProxReenc === 0) {
                return 0;
            }

            const value = this.RemanentesMedida[index1].reduce((count, element) => {
                return count + ((rmParaReenc < element) && (element <= rmProxReenc + 1) ? 1 : 0);
            }, 0);

            // console.log('value RProxR> ', value);
            return value;
        },

        calcularPorcentajes: function (cantidad, total) {
            let porcentaje = 0;
            total = total == 0 || total == undefined || total == null ? 1 : total;
            cantidad =
                cantidad == 0 || cantidad == undefined || cantidad == null ?
                    0 :
                    cantidad;
            porcentaje = (cantidad / total) * 100;

            return porcentaje.toFixed(2) + "%";
        },

        mostrarPorcentaje: function (array) {
            if (array.length > 0) {
                return "100%";
            } else {
                return "0%";
            }
        },

        graficoIndiceReencauche: function () {
            let etiquetas = [];
            let data = [];

            this.IndiceReencauche.forEach((element) => {
                let descripcion =
                    element.description + " (" + element.porcentaje + "%) ";
                etiquetas.push(descripcion);
                data.push(element.cantidad);
            });
            this.graficoPie("pieReencauche", etiquetas, "", data);
        },

        graficoIndiceReencauchabilidad: function () {
            let array = [];
            let data = [];
            let etiquetas = [];
            array.push({
                descripcion: "Reencauchabilidad",
                cantidad: this.IndiceReencauchabilidad,
            });

            array.forEach((element) => {
                etiquetas.push(element.descripcion);
                data.push(element.cantidad);
            });

            var popCanvas = document.getElementById("barReencauchabilidad");
            new Chart(popCanvas, {
                type: "bar",
                data: {
                    labels: etiquetas,
                    datasets: [{
                        label: "Neumáticos",
                        data: data,
                        backgroundColor: ["rgb(27, 205, 249)"],
                        hoverOffset: 4,
                    },],
                },
                options: {
                    legend: {
                        display: false,
                    },
                    scales: {
                        yAxes: [{
                            type: "linear",
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                suggestedMax: 2,
                            },
                        },],
                    },
                    tooltips: {
                        yAlign: "bottom",
                        xAlign: "center",
                        xPadding: 25,
                        yPadding: 15,
                        xPadding: 45,
                        _bodyAlign: "center",
                        _footerAlign: "center",
                        backgroundColor: "#ccc",
                        titleFontSize: 16,
                        titleFontColor: "#0066ff",
                        bodyFontColor: "#000",
                        bodyFontSize: 14,
                        displayColors: false,
                    },
                },
            });
        },
        tamanioTabla: function () {
            if (this.Mini == true) {
                return "max-width: 950px";
            } else {
                return "max-width: 860px";
            }
        },
        guardarRecomendacion: async function (item) {
            if (item) {
                this.Item.id_recomendacion = item.id;
                this.Item.descripcion_recomendacion = item.recomendaciones;
            } else {
                this.Item.id_recomendacion = this.recomendacion.id;
                this.Item.descripcion_recomendacion = this.recomendacion.descripcion;
                this.recomendacion = {};
            }

            let rpta = await this.saveRecomendacion(this.Item);
            if (rpta == true) {
                this.$alertify.success("Agregado con éxito ");
            } else {
                this.$alertify.success("Editado con éxito");
            }
        },
        eliminarRecomendacion: function (index, item) {
            this.dialog = true;
            this.recomendacion.index = index;
            this.recomendacion.id = item.id;
            this.recomendacion.descripcion = item.recomendaciones;
        },
        confirmar: function () {
            let data = {
                id: this.recomendacion.id,
                index: this.recomendacion.index,
            };
            this.deleteRecomendacion(data);
            this.dialog = false;
            this.recomendacion = {};
        },
        generarPDF: async function (item) {
            this.dialogPDF = true;
            this.loaderpdf = true;

            const data = {
                id_cliente: localStorage.getItem("idcliente"),
                id_usuario: localStorage.getItem("id"),
                year: item.year,
                month1: item.month1,
                month2: item.month2,
            };

            try {
                const url = "reporte/savePdf";
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.pdf = response.data.datos;
                        this.endpoint = response.data.endpoint;
                        //this.ver_iframe = true;
                        this.$nextTick().then(() => {
                            this.ver_iframe = true;
                        });
                    })
                    .catch((error) => {
                        console.error("error!", error);
                        this.errorMessage = error.response.data;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        cerrarVisor: function () {
            this.dialogPDF = false;
            this.ver_iframe = false;
        },

        _json_to_query_string: function (json) {
            json = Object.keys(json).map(item => {
                return item + '=' + json[item] || '';
            });
            return json.join("&");
        },

        exportarAExcelScrapxMotivo: async function () {
            try {
                this.loadingExcelScrap = true;
                const url =
                    global.ruta_api +
                    "excel/reportescrapmotivo?" +
                    global._json_to_query_string({
                        id_cliente: localStorage.getItem('idcliente'),
                        year: this.Item.year,
                        month1: this.Item.month1,
                        month2: this.Item.month2
                    });

                await axios({
                    url: url,
                    method: "GET",
                    responseType: "blob"
                })
                    .then(async (response) => {
                        this.loadingExcelScrap = false;
                        var nombre_archivo = response.headers["nombre-archivo"];
                        if (response.data.type == "text/html") {
                            // no es excel, probablemente es json con un mensaje de error
                            const res = JSON.parse(await response.data.text());
                            if (!res.exito) {
                                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
                            }
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", nombre_archivo);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        calcularCantidadNeumaticosScrap() {
            let cantidad = 0;
            this.NeumaticosScrap.forEach((element) => {
                cantidad += element.cantidad;
            })
            return cantidad;
        },

        calcularPorcentajeNeumaticosScrap() {
            let porcentaje = 0;
            this.NeumaticosScrap.forEach((element) => {
                porcentaje += element.porcentaje;
            })
            return porcentaje.fixed();
        }
    },
    async mounted() {
        await this.cargarAnios();
        this.rutaImagenFirma = global.ruta_image + this.Usuario.img_firma;
        this.nombreusuario = this.Usuario.nombres + " " + this.Usuario.apellidos;
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
}

/*
td {
    vertical-align: top;
}
*/

.border {
    border-style: solid;
    border-width: 1px;
}

.border-bottom {
    border-bottom: solid;
    border-width: 1px;
}

.border-top {
    border-top: solid;
    border-width: 1px;
}

.border-left {
    border-left: solid;
    border-width: 1px;
}

.border-right {
    border-right: solid;
    border-width: 1px;
}

.no-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.no-border-left {
    border-left: none;
}

.no-border-bottom {
    border-bottom: none;
}

.no-border-right {
    border-right: none;
}

.texto-cen {
    text-align: center;
}

.texto-izq {
    text-align: left;
}

.texto-just {
    text-align: justify;
}

.inline {
    display: inline-block;
}

.texto-arriba {
    vertical-align: text-top;
    vertical-align: top;
}

.letra-6 {
    font-size: 6pt;
}

.letra-7 {
    font-size: 7pt;
}

.letra-8 {
    font-size: 8pt;
}

.letra-9 {
    font-size: 9pt;
}

.letra-10 {
    font-size: 10pt;
}

.letra-11 {
    font-size: 11pt;
}

.letra-12 {
    font-size: 12pt;
}

.letra-14 {
    font-size: 14pt;
}

.letra-15 {
    font-size: 15pt;
}

.letra-18 {
    font-size: 18pt;
}

.letra-25 {
    font-size: 25pt;
}

.letra-30 {
    font-size: 30pt;
}

.margin-top-20 {
    margin-top: 20px;
}

.bg-celda-azul {
    background: #91e3da;
}

.bg-celda-amarilla {
    background: #faec5c;
    /*color:#EDDF04;*/
}

.bg-celda-verde {
    background: #a8fa6f;
}

.bg-celda-roja {
    background: #f55f50;
}

#table {
    display: block;
    /* important */
    width: 100px;
    overflow-x: scroll;
}
</style>
