<template>
    <div>
        <v-row justify="center">
            <v-col lg="12">
                <v-card>

                    <v-toolbar color="secondary" dark>
                        <span class="text-h6"> {{ Titulo }}: {{ ItemTotal }}</span>
                        <v-spacer></v-spacer>
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                    <a target="_blank" href="https://helpdesk.tiresoft.pe/#reporte_inspeccion"
                                        style="text-decoration:none !important; color: white;">
                                        <v-icon dark>mdi-help-circle</v-icon>
                                    </a>
                                </v-btn>
                            </template>
                            <span>¿Necesitas ayuda?</span>
                        </v-tooltip>
                    </v-toolbar>

                    <!-- <v-toolbar color="secondary" dark>
                    {{ Titulo }} : {{ ItemTotal }}
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" fab dark small color="orange">
                        <v-icon dark> mdi-alert </v-icon>
                    </v-btn>
                </v-toolbar> -->
                    <v-card-title>
                        <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                            Descargar Tabla
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                            hide-details></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                            Nuevo
                        </v-btn>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" class="mr-3" dark v-bind="attrs" v-on="on">
                                    Exportar
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in opcionesAexportar" :key="index"
                                    @click="exportarAExcel1(item.text)">
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <!-- Botón de Exportar General que abre el modal -->
                        <v-btn color="success" dark @click="dialogexport = true ; loadingExportgeneral=false;">
                        Exportar General
                        </v-btn>
                    </v-card-title>

                    <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                        :loading="loading" loading-text="Cargando... Por favor, espere" class="elevation-1"
                        v-if="mostrarTabla" no-data-text="No hay datos disponibles" :footer-props="{
                            'items-per-page-text': 'Resultados por página:',
                            'page-text': '{0}-{1} de {2}'
                        }">
                        <template v-slot:[`item.acciones`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn style="margin-left: 2px" height="22" width="22" color="blue lighten-2" fab
                                        x-small dark @click="editarItem(item)" v-bind="attrs" v-on="on">
                                        <v-icon x-small>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn style="margin-left: 2px; margin-top: 2px" height="22" width="22"
                                        color="#FE9B2F" fab x-small dark @click="detallesItem(item)"
                                        :loading="item.loader" v-bind="attrs" v-on="on">
                                        <v-icon x-small>mdi-format-list-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>Detalles</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center">
            <vue-loaders v-if="loadingExport == true" name="ball-pulse" color="#FF5252" scale="1"></vue-loaders>
        </v-row>

        <v-dialog v-model="dialognuevo" max-width="950px" @click:outside="cerrarModalNuevo">
            <Nuevo :Item="itemSelected" :Cliente="Cliente" @cargarPosiciones="cargarPosiciones"
                @editarInspeccion="editarInspeccionMinified" @fechavalida="fechavalida"
                @cerrarModalNuevo="cerrarModalNuevo">
            </Nuevo>
        </v-dialog>
        <!-- Modal para exportar con fechas y opciones -->
        <v-dialog v-model="dialogexport" max-width="500px" @click:outside="cerrarModalExport()">
        <v-card>
            <v-card-title class="headline">Exportar General</v-card-title>
            
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-menu
                            ref="menufechaini"
                            v-model="menufechaini" :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="fechainiform"
                                label="Fecha Inicio"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"  
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker no-title scrollable  color="primary"  v-model="fechaInicio" :max="fechamaxini" @input="menufechaini = false; fechainiform=formateadofecha(fechaInicio)" locale="es-pe" > </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6"> 
                        <v-menu
                            ref="menufechafin"
                            v-model="menufechafin" :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="fechafinform"
                                label="Fecha Fin"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"  
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker no-title scrollable color="primary" v-model="fechaFinal" @input="menufechafin = false; fechafinform=formateadofecha(fechaFinal)" :min="fechaInicio" :max="fechamaxfin" locale="es-pe" > </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="light" @click="cerrarModalExport()">Cancelar</v-btn>
                <v-spacer></v-spacer> 
                <template v-for="(item, index) in opcionesAexportar" >
                    <v-btn color="primary" @click="exportarAExcel2(item.text)">{{ item.text }}</v-btn>
                    <v-overlay v-if="loadingExportgeneral"
                        :model-value="loadingExportgeneral"
                        class="align-center justify-center"
                        >
                        <div class="text-center">
                            EXPORTACION EN PROCESO<br><br>
                            <v-progress-circular
                                color="primary"
                                size="64"
                                indeterminate
                            ></v-progress-circular>
                        </div>
                    </v-overlay>
                </template> 
                
    
            </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="dialogdetalles" width="1100" @click:outside="cerrarModalDetallesPorIdentificador">
            <TablaDetalles :Inspecciones="Inspecciones" @editarInspeccionParcial="editarInspeccionParcial"
                :Identificador="identificador" :mostrarBotonEliminar="false" v-if="verTablaDetalles" />
        </v-dialog>

        <Detalle ref="detalle" :dialog="dialog" :Item="itemSelected" :Ejes="Ejes"
            :MotivosInaccesibilidad="MotivosInaccesibilidad" :TiposValvula="TiposValvula" :Duales="duales"
            :Observaciones="observaciones" @agregarInspeccionParcial="agregarInspeccionParcial"
            @cerrarModal="cerrarModal" v-if="dialog" />
    </div>
    
</template>
<style>
.v-date-picker-table--date th {
    
  border: none !important;
  background-color: transparent !important;
  color: inherit !important; /* Para heredar el color del texto del contenedor */
} 

/* Si también deseas remover el borde de las celdas (botones) de la tabla */
.v-date-picker-table td button {
  border: none !important;
  background-color: transparent !important;
}
</style>

<script>
import Nuevo from "./nuevo.vue";
import axios from "axios";
import TablaDetalles from "@/components/inspeccion/detalles.vue";
import Detalle from "@/components/inspeccion/detalle.vue";
import global from "@/global";
import {
    notif
} from "@/plugins/notyf"

import {
    mapState,
    mapActions
} from "vuex";
import moment from "moment";

export default {
    props: [
        "Titulo",
        "Cabecera",
        "loading",
        "Items",
        "ItemTotal",
        "Excel",
        "Crear",
    ],
    components: {
        Nuevo,
        Detalle,
        TablaDetalles
    },
    data() {
        return {
            search: "",
            itemSelected: {},
            Inspecciones: [],
            loader: false,
            dialog: false,
            dialogexport:false,
            dialognuevo: false,
            dialogdetalles: false,
            duales: [{
                opcion: false,
                text: "Diseño",
            },
            {
                opcion: false,
                text: "Tamaño",
            },
            {
                opcion: false,
                text: "Tipo de Construcción",
            },
            {
                opcion: false,
                text: "Medida de Neumático",
            },
            {
                opcion: true,
                text: "No Aplica",
            },
            ],
            observaciones: [{
                name: "Desg. Irregular",
                opcion: false,
                text: "Desg. Irregular",
            },
            {
                name: "Lista para Reparar",
                opcion: false,
                text: "Para Reparar",
            },
            {
                name: "Aro Defectuoso",
                opcion: false,
                text: "Aro Defectuoso",
            },
            {
                name: "Fallas en el flanco",
                opcion: false,
                text: "Fallas en el flanco",
            },
            ],
            identificador: "",
            mostrarTabla: true,
            verTablaDetalles: false,
            opcionesAexportar: [
                {
                    id: 1,
                    text: "Exportar Excel",
                },
                {
                    id: 2,
                    text: "Exportar CSV",
                },
            ],
            loadingExport: false,  
            loadingExportgeneral: false,  
            fechainiform: moment().format('DD/MM/YYYY'),
            fechafinform: moment().format('DD/MM/YYYY'),
            fechaInicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menufechaini: false,
            fechaFinal: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menufechafin: false,
            fechaminini:moment().format('YYYY-MM-DD'),
            fechamaxini:moment().format('YYYY-MM-DD'),
            fechaminfin:moment().format('YYYY-MM-DD'),
            fechamaxfin:moment().format('YYYY-MM-DD'),
            cantmesmax_export:12, 
        };
    }, 
    watch: {  
        dialogexport(val){ 
            this.fechafinform= moment().format('DD/MM/YYYY'),
            this.loadingExportgeneral=false;
            this.menufechaini= false,
            this.menufechafin= false,
            this.fechaFinal= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            this.fechaInicio= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            this.fechainiform= moment().format('DD/MM/YYYY'),
            this.fechaminini=moment().format('YYYY-MM-DD'),
            this.fechamaxini=moment().format('YYYY-MM-DD'),
            this.fechaminfin=moment().format('YYYY-MM-DD'),
            this.fechamaxfin=moment().format('YYYY-MM-DD'),
            this.$nextTick().then(() => {
                this.mostrarTabla = true;
            });
        },
        fechaInicio(val){  
            if (val) {
                const fechainic = new Date(val);
                
                // Restar el número de meses especificado (24 meses en este caso)
                fechainic.setMonth(fechainic.getMonth() + this.cantmesmax_export);

                if (this.fechamaxfin>fechainic.toISOString().split('T')[0] || this.fechamaxfin<this.fechaInicio) {
                    // Convertir a formato YYYY-MM-DD y asignar a fechaminini
                    if (fechainic.toISOString().split('T')[0]>moment().format('YYYY-MM-DD')) {
                        this.fechamaxfin=moment().format('YYYY-MM-DD');
                    }else{

                        this.fechamaxfin = fechainic.toISOString().split('T')[0]; 
                    }
                } 

                if (this.fechaInicio>this.fechaFinal) {
                    this.fechaminfin=this.fechaInicio;
                    this.fechaFinal=this.fechaInicio;
                    
                    this.fechafinform= moment(this.fechaInicio).format('DD/MM/YYYY');
                }

                // Comparar si la fecha mínima es mayor que la fecha de inicio
                if (this.fechaFinal && this.fechamaxfin <= this.fechaFinal) {
                    // Si la fecha mínima es mayor, ajustar la fecha de inicio
                    this.fechaFinal = this.fechamaxfin;
                    this.fechafinform=moment(this.fechaFinal).format('DD/MM/YYYY'); 
                }
            }
        },
    },
    created() {
        this.ObtenerDatosCliente();
        this.cargarDatosAdicionales();
    },
    computed: {
        ...mapState("cliente", ["Cliente"]),
        ...mapState("inspeccion", [
            "Ejes",
            "MotivosInaccesibilidad",
            "TiposValvula",
        ]),
    },
    methods: {
        
        ...mapActions("cliente", ["ObtenerDatosCliente"]),
        ...mapActions("inspeccion", [
            "cargarInspecciones",
            "cargarInspeccionesPorIdentificador",
            "cargarDatosAdicionales",
        ]),
 
        formateadofecha(fecha){
            if (!fecha) return null

            const [year, month, day] = fecha.split('-')
            return `${day}/${month}/${year}`
        },
        validateFechaFinal:function(){
            if (this.fechaFinal && this.fechaInicio) {
                return this.fechaFinal>=this.fechaInicio;
            }
            return true;
        },
        cargarPosiciones: function () { },

        fechavalida: function (fecha) {
            this.itemSelected.fecha_inspeccion = fecha;
        },

        editarItem: function (item) {
            this.itemSelected = {
                ...item,
            };
            this.itemSelected.codigo =
                this.itemSelected.insp_codigo == "-" ?
                    null :
                    this.itemSelected.insp_codigo;
            this.itemSelected.repuesto =
                this.itemSelected.repuesto == null ? 0 : this.itemSelected.repuesto;
            this.itemSelected.portallantas =
                this.itemSelected.portallantas == null ?
                    0 :
                    this.itemSelected.portallantas;
            this.dialognuevo = true;
        },

        detallesItem: async function (item) {
            item.loader = true;
            this.verTablaDetalles = true
            this.identificador = item.identificador;
            let array_id_inspecciones = await this.cargarInspeccionesPorIdentificador(
                this.identificador
            );
            try {
                const url = "inspecciones/obtenerInspeccionesParcial";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    array_id_inspecciones: array_id_inspecciones,
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.Inspecciones = response.data.inspecciones;
                        item.loader = false;
                        this.dialogdetalles = false;
                        this.$nextTick().then(() => {
                            this.dialogdetalles = true;
                        });
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        cerrarModalDetallesPorIdentificador: function () {
            this.mostrarTabla = false;
            this.verTablaDetalles = false;
            this.dialogdetalles = false;
            this.$nextTick().then(() => {
                this.mostrarTabla = true;
            });

        },

        cerrarModalExport: function () { 
            this.dialogexport = false;
            this.fechainiform= moment().format('DD/MM/YYYY'),
            this.fechafinform= moment().format('DD/MM/YYYY'),
            this.loadingExportgeneral=false;
            this.fechaInicio= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            this.menufechaini= false,
            this.menufechafin= false,
            this.fechaFinal= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            this.$nextTick().then(() => {
                this.mostrarTabla = true;
            });
        },

        cerrarModalNuevo: function () {
            this.mostrarTabla = false;
            this.dialognuevo = false;
            this.$nextTick().then(() => {
                this.mostrarTabla = true;
            });
        },

        editarInspeccionParcial: function (item) {
            this.itemSelected = {
                ...item,
            };
            item.array_resultado.forEach((element1) => {
                this.duales.forEach((element2) => {
                    if (element1 == element2.text) {
                        element2.opcion = true;
                    }
                });
            });

            item.array_otros.forEach((element1) => {
                this.observaciones.forEach((element2) => {
                    if (element1 == element2.name) {
                        element2.opcion = true;
                    }
                });
            });
            this.duales = this.duales;
            this.observaciones = this.observaciones;
            this.dialog = true;
        },

        agregarInspeccionParcial: async function (id) {
            //cargarInspeccion
            this.detallesItem({
                identificador: this.itemSelected.identificador
            });
            this.dialog = false;
        },

        editarInspeccionMinified: async function (item) {
            this.cerrarModalNuevo();
            await this.cargarInspecciones();
        },

        cerrarModal: function () {
            this.dialog = false;
        },

        async exportarAExcel1(extension1) {
            try {
                this.loadingExport = true;
                const url =
                    global.ruta_api +
                    "excel/reporteinspeccionesminified?" +
                    global._json_to_query_string({
                        id_cliente: localStorage.getItem("idcliente"),
                        extension: extension1,
                    });

                await axios({ url: url, method: "GET", responseType: "blob" })
                    .then(async (response) => {
                        this.loadingExport = false;
                        console.log("loading2", this.loadingExport);
                        var nombre_archivo = response.headers["nombre-archivo"];
                        if (response.data.type == "text/html") {
                            // no es excel, probablemente es json con un mensaje de error
                            const res = JSON.parse(await response.data.text());
                            if (!res.exito) {
                                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
                            }
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", nombre_archivo);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        async exportarAExcel2(extension2) {
            try { 
                this.loadingExportgeneral = true;
                const url =
                    global.ruta_api +
                    "excel/reporteinspecciones?" +
                    global._json_to_query_string({
                        id_cliente: localStorage.getItem("idcliente"),
                        id_usuario: localStorage.getItem("id"),
                        fechaini: this.fechaInicio,
                        fechafin: this.fechaFinal,
                        extension: extension2,
                    });

                await axios({ url: url, method: "GET", responseType: "blob" })
                    .then(async (response) => {
                        this.loadingExportgeneral = false;
                        var nombre_archivo = response.headers["nombre-archivo"];
                        if (response.data.type == "text/html") {
                            // no es excel, probablemente es json con un mensaje de error
                            const res = JSON.parse(await response.data.text());
                            if (!res.exito) {
                                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
                            }
                        } else {
                            this.dialogexport=false;
                            notif.success("Se Realizo la Exportacion Exitosamente");
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", nombre_archivo);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>
